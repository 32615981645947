import React from "react";
import Header from "../components/Header";
import Section from "../components/Section";
import SEO from "../components/SEO";
import styled from "styled-components";

import { BiHealth } from "react-icons/bi";
import { below } from "../styles/Breakpoints";

const StyledAboutPage = styled.div`
  padding-bottom: 100px;
  ${below.mobileL`
    padding-bottom: 0;
  `}

  p {
    margin-bottom: 1rem;
  }

  h2 {
    @supports (font-size: clamp(2rem, (1rem + 5vw), 2.3rem)) {
      font-size: clamp(2rem, (1rem + 5vw), 2.3rem);
    }
    margin-bottom: 1.25rem;
    margin-top: 2rem;
    width: 100%;
    ${below.mobileL`
      margin-top: 1.5rem;
    `}
    @supports not(font-size: clamp(2rem, (1rem + 5vw), 2.3rem)) {
      ${below.mobileL`
        font-size: 2rem;
      `}
    }
  }

  .about-list {
    margin-block-start: 0;
    -webkit-margin-before: 0;
    padding: 0;
    & li {
      margin-bottom: 0.75rem;
      font-weight: 600;
      list-style-type: none;
      display: flex;
      align-items: center;
      & svg {
        margin-right: 0.6rem;
        width: 14px;
        min-width: 14px;
        height: 100%;
        min-height: 14px;
        color: var(--primaryPop);
      }
    }
  }

  .list-wrapper {
    margin: 0 auto 1rem;
    width: min(600px, 100%);
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    &:last-of-type {
      margin-top: 1rem;
    }

    ${below.mobileL`
      flex-direction: column;
        & ul {
          margin-bottom: 0;
        }
        & .sub-list-wrapper {
        margin: 0 auto;
      }
    `}

    &--values {
      ${below.mobileL`
        .sub-list-wrapper {
          width: 150px;
        }
      `}
    }

    &--principles {
      gap: 0.5rem;
      ${below.mobileL`
        gap: 0;
        .sub-list-wrapper {
          width: 270px;
        }
      `}
    }
  }
`;

export default function About({ location }) {
  return (
    <StyledAboutPage>
      <SEO title="About Us" location={location} />
      <Section>
        <Header headerText="About Us" id="aboutHeader" />
        <div className="header-underline"></div>
        <p>
          We imagine a world without violence, particularly the{" "}
          <strong>violence of institutions</strong> that tend to neglect or
          actively punish individuals according to their access to resources. We
          aim to build systems that affirm life, creativity, and healing and
          that differ completely than our punishment-based systems (e.g.,
          prisons). Too often, organizations use punishment when individuals,
          families, and communities need support and resources. We see a future
          where every conflict, misunderstanding, abuse, or harm can lead to{" "}
          <strong>transformation</strong> and growth.
        </p>
        <p>
          As a group, we are learning, growing, and spreading the ideas of other
          organizers and communities that came before us. We work alongside
          organizations focused on restorative and transformative justice
          principles. We want to build practices, informational resources,
          trainings, and services that make punishment like prisons become
          unnecessary.
        </p>
        <p>
          <span className="bold" style={{ color: "var(--primaryPop" }}>
            Restorative Justice:
          </span>{" "}
          A theory, principles, and practices that focus on restoring what was
          lost after harm occurs. This approach looks at what unmet needs led to
          harm and what need comes from the harm. From this understanding, we
          might realize what repair need to be made. Specific practices might
          include Circle Keeping and restorative conferencing between the person
          who harmed and the victim.
        </p>
        <p>
          <span className="bold" style={{ color: "var(--primaryPop" }}>
            Transformative Justice:
          </span>{" "}
          A theory, principles, and practice that goes beyond restorative
          justice and looks at the conditions that allowed for the harm to
          happen in the first place. It is also a political stance and focuses
          on grassroots community responses. Transformative Justice
          practitioners, despite using similar practices to Restorative Justice,
          refuses to work with institutions or the state. Transformative Justice
          assumes that restoration is not the goal, but transformation instead.
          This movement is led by mostly queer Black, Indigenous, and People of
          Color, who come from communities that don’t have resources and have
          historically been policed and punished rather than supported.
        </p>

        <h2>Our values</h2>
        <div className="list-wrapper list-wrapper--values">
          <div className="sub-list-wrapper">
            <ul className="about-list">
              <li>
                <BiHealth />

                <strong>Humanity</strong>
              </li>
              <li>
                <BiHealth /> Interdependence
              </li>
              <li>
                <BiHealth /> Creativity
              </li>
              <li>
                <BiHealth /> Mutuality
              </li>
            </ul>
          </div>
          <div className="sub-list-wrapper">
            <ul className="about-list">
              <li>
                <BiHealth /> Community
              </li>
              <li>
                <BiHealth /> Imagination
              </li>
              <li>
                <BiHealth /> Transformation
              </li>
              <li>
                <BiHealth /> Joy
              </li>
            </ul>
          </div>
        </div>

        <h2>
          <span style={{ display: "inline-block" }}>Our principles </span>{" "}
          <span style={{ display: "inline-block" }}> and practices</span>
        </h2>

        <p>
          We aim to practice what we preach and develop our own habits of
          empathy, compassion, and taking <strong>accountability</strong>{" "}
          personally and within our team. For example, we start each board
          meeting with sharing an apology experience.
        </p>
        <p>
          We believe that “justice” is hard. It is even harder when there are
          large power differences between individuals or people and
          institutions. Transforming harm should look at the following areas:
        </p>

        <div className="list-wrapper list-wrapper--principles">
          <div className="sub-list-wrapper">
            <ul className="about-list">
              <li>
                <BiHealth /> Power and agency
              </li>
              <li>
                <BiHealth /> Unconditional financial income
              </li>
              <li>
                <BiHealth /> Safe housing
              </li>
              <li>
                <BiHealth /> Food
              </li>
            </ul>
          </div>
          <div className="sub-list-wrapper">
            <ul className="about-list">
              <li>
                <BiHealth /> Childcare assistance
              </li>
              <li>
                <BiHealth /> Education
              </li>
              <li>
                <BiHealth /> Access to jobs
              </li>
              <li>
                <BiHealth /> Healthcare
              </li>
            </ul>
          </div>
        </div>
      </Section>
    </StyledAboutPage>
  );
}
